.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, 0.8);
  z-index: 999;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: white;
    font-size: 18px;
  }
}