.photo-viewer {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 96vw;
    max-height: 96vh;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%)
  }
}