.about {
  display: flex;
  flex-wrap: wrap;
  
  & > * {
    flex-grow: 1;
    flex-basis: 50%;
    padding: 4%;;
  }

  .description {
    text-align: left;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.35px;
    line-height: 32px;
    text-size-adjust: 100%;
    -webkit-font-smoothing: anti-aliased;
  }

  img {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    & > * {
      flex-basis: 100%;
    }
  }
}