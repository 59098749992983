.header {
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  padding-left: 6vw;
  padding-right: 6vw;
  pointer-events: auto;
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.2em;
  font-size: 1rem;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 300;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  line-height: 1.8em;
  font-size: 1.1rem;
  color: black;
  z-index: 1;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */

  a {
    margin-left: 1vw;
    margin-right: 1vw;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &.active {
      border-bottom: 1px solid black;
    }
  }
  
  img,a {
    width: auto;
    max-width: 100%;
    max-height: 145px;
  }

  .nav-desktop {
    text-align: center;
    flex: 0 0 100%;
    margin: 1.6vw 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nav-mobile {
    display: none;
    z-index: 998;
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s opacity ease-in;
  }

  .nav-mobile-trigger {
    display: none;
    top: 9px;
    left: 16px;
    position: absolute;
    z-index: 999;
  }

  /* Portrait */
  @media screen and (orientation:portrait) and (max-width: 800px) {
    .nav-desktop {
      display: none;
    }
    
    
    .nav-mobile {
      display: flex;
      visibility: hidden;
      opacity: 0;

      &.visible {
        visibility: visible;
        opacity: 1;
      }

      a {
        margin: 16px auto;
        font-size: 2rem;
      }
    }

    img, a {
      max-height: 60px;
    }

    .nav-mobile-trigger {
      display: block;
      
    }
  }
}